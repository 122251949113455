import { ApolloError, gql, useApolloClient } from "@apollo/client";
import { sleep } from "@hex/common";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Subscription } from "zen-observable-ts";

import { HexButton, HexSpinner, HexTooltip } from "../../hex-components";
import { HexClasses } from "../../hex-components/classes.js";
import { useHexVersionSelector } from "../../hex-version-multiplayer/state-hooks/hexVersionStateHooks";
import { useUserForMagic } from "../../hooks/magicHooks";
import { useProjectVersionEditable } from "../../hooks/sessionOrProjectEditableHooks.js";
import { MagicOnboardingPopover } from "../../magic/MagicOnboardingPopover";
import {
  MagicStreamingResponseDocument,
  MagicStreamingResponseSubscription,
  MagicStreamingResponseSubscriptionVariables,
} from "../../magic/useCreateSmartEdit.generated";
import { Keys } from "../../util/Keys";
import { useProjectContext } from "../../util/projectContext";
import { CharacterCountIndicator } from "../common/CharacterCountIndicator";
import { useToaster } from "../common/Toasts";
import { MagicIcon } from "../icons/CustomIcons";

import {
  MagicDescribeProjectDocument,
  MagicDescribeProjectQuery,
  MagicDescribeProjectQueryVariables,
} from "./ProjectDescription.generated";

export const DESCRIPTION_MAX_LENGTH = 1000;

gql`
  query MagicDescribeProject($hexVersionId: HexVersionId!) {
    magicDescribeProject(hexVersionId: $hexVersionId) {
      magicEventId
      responseOverride
    }
  }
`;

export const BorderStyles = css`
  padding: 3px;

  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: text;

  transition: box-shadow 0.2s ease;

  &:focus {
    box-shadow: ${({ theme }) => theme.boxShadow.FOCUS_PRIMARY};
  }

  &:hover:not(:focus) {
    ${({ theme }) =>
      `box-shadow: inset 0 0 0 1px ${theme.borderColor.DEFAULT};`}
  }
`;

export const DescriptionWrapper = styled.div`
  position: relative;
  padding-left: 30px;
  left: -30px;

  display: flex;
  flex: 0 1 auto;

  &:hover {
    .${HexClasses.HEX_BUTTON} {
      visibility: visible;
    }
  }
`;

const MagicDescribeButton = styled(HexButton)`
  margin-top: 3px;
  margin-left: -20px;
  visibility: hidden;
`;

const MagicDescLoadingSpinner = styled(HexSpinner)`
  flex: none;
  margin-left: -20px;
`;

export const DescriptionTextArea = styled.textarea<{
  $editable: boolean;
  $small?: boolean;
}>`
  display: block;
  width: 100%;
  max-width: 700px;

  overflow: hidden;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-size: ${({ $small, theme }) =>
    !$small ? theme.fontSize.DEFAULT : theme.fontSize.SMALL};

  line-height: 20px;
  white-space: normal;

  background: transparent;

  border: none;
  outline: none;

  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.fontColor.PLACEHOLDER};
  }

  ${({ $editable }) =>
    $editable
      ? BorderStyles
      : css`
          padding: 3px;
        `}
`;

export const DescriptionLengthLimit = styled(CharacterCountIndicator)`
  position: absolute;
  right: 3px;
  bottom: -9px;
`;

export const PROJECT_DESCRIPTION_HTML_ID = "project-metadata-description";

interface ProjectDescriptionProps {
  small?: boolean;
  canEdit: boolean;
  onSaveDescription: (description: string | null) => void;
}

export const ProjectDescription: React.ComponentType<ProjectDescriptionProps> =
  React.memo(function ProjectDescription({
    canEdit,
    onSaveDescription,
    small = false,
  }) {
    const { hexVersionId } = useProjectContext();
    const projectVersionEditable = useProjectVersionEditable();
    const { magicEnabled, onboardedToMagic } = useUserForMagic();

    const description = useHexVersionSelector({
      selector: (hexVersion) => hexVersion.description,
    });
    const subscriptionRef = useRef<Subscription | undefined>(undefined);

    const [currentDescription, setCurrentDescription] = useState<string>("");
    const [editingDescription, setIsEditingDescription] =
      useState<boolean>(false);
    const [magicDescLoading, setMagicDescLoading] = useState(false);

    const canEditWithMagic = canEdit && projectVersionEditable && magicEnabled;

    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const toaster = useToaster();

    const saveDescription = useCallback(
      (desc: string): void => {
        if (desc !== "") {
          onSaveDescription(desc);
        } else {
          onSaveDescription(null);
        }
        setIsEditingDescription(false);
      },
      [onSaveDescription],
    );

    useEffect(() => {
      setCurrentDescription(description ?? "");
    }, [description]);
    const client = useApolloClient();
    const magicDescribe = useCallback(() => {
      setMagicDescLoading(true);
      setCurrentDescription("");
      client
        .query<MagicDescribeProjectQuery, MagicDescribeProjectQueryVariables>({
          query: MagicDescribeProjectDocument,
          fetchPolicy: "network-only",
          variables: {
            hexVersionId,
          },
        })
        .then(async (result) => {
          if (result.data) {
            subscriptionRef.current?.unsubscribe();
            const { magicEventId, responseOverride } =
              result.data.magicDescribeProject;
            let inFlightDescription = "";
            if (magicEventId == null) {
              await sleep(200);
              // Let's be cute ans simulate streaming in the hardcoded response
              const response = responseOverride ?? "";
              for (const word of response.split(" ")) {
                inFlightDescription += word + " ";
                await sleep(200);
                setCurrentDescription(inFlightDescription);
              }
              setMagicDescLoading(false);
              saveDescription(response.trim());
              return;
            }
            subscriptionRef.current = client
              .subscribe<
                MagicStreamingResponseSubscription,
                MagicStreamingResponseSubscriptionVariables
              >({
                query: MagicStreamingResponseDocument,
                fetchPolicy: "network-only",
                variables: { magicEventId },
              })
              .subscribe({
                next: (data) => {
                  const token = data.data?.magicStreamingResponse.token;
                  const errorMsg = data.data?.magicStreamingResponse.error;
                  // If we get a reset message, it means we're trying again with the
                  // fallback model, so clear any tokens we've already streamed
                  if (data.data?.magicStreamingResponse.reset) {
                    inFlightDescription = "";
                    setCurrentDescription("");
                  }
                  if (errorMsg) {
                    subscriptionRef.current?.unsubscribe();
                    setMagicDescLoading(false);
                    toaster.show({ message: errorMsg, intent: "danger" });
                  } else if (token) {
                    inFlightDescription += token;
                    setCurrentDescription(inFlightDescription);
                  } else if (data.data?.magicStreamingResponse.done) {
                    setMagicDescLoading(false);
                    saveDescription(inFlightDescription);
                    subscriptionRef.current?.unsubscribe();
                  }
                },
              });
          }
        })
        .catch((e: ApolloError) => {
          setMagicDescLoading(false);
          console.error(e);
          subscriptionRef.current?.unsubscribe();
          toaster.show({
            message:
              "We encountered an error generating your project description. Please try again later.",
            intent: "danger",
          });
        });
    }, [client, hexVersionId, saveDescription, toaster]);

    // This allows the textarea to grow with the content
    useEffect(() => {
      if (descriptionRef.current != null) {
        descriptionRef.current.style.height = "0px";
        const scrollHeight = descriptionRef.current.scrollHeight;
        descriptionRef.current.style.height = scrollHeight + "px";
      }
    }, [currentDescription]);

    const descriptionTextAreaCallback = useCallback((evt) => {
      setCurrentDescription(evt.target.value);
    }, []);

    const maybeFocusDescription = useCallback(() => {
      if (canEdit) {
        setIsEditingDescription(true);
      }
    }, [canEdit, setIsEditingDescription]);

    const handleKeydown = useCallback(
      (evt: React.KeyboardEvent) => {
        if (evt.key === Keys.ESCAPE) {
          setCurrentDescription(description ?? "");
          setIsEditingDescription(false);
        }
      },
      [description],
    );

    // SUP-958: use keypress instead of keydown to better handle multibyte
    // characters. Keypress only gets called when a character value is produced
    // whereas keydown gets called for all key presses. This means that
    // for languages like japanese where the user can be pressing Enter
    // in the IME to select a suggested value, we could trigger saving
    // the value before the user is done typing, which isn't correct.
    // With keypress, as the suggestion selection doesn't produce a character
    // value, it doesn't trigger the callback, and only triggers a callback
    // when in non-IME mode.
    const handleKeyPress = useCallback(
      (evt: React.KeyboardEvent) => {
        if (evt.key === Keys.ENTER && !evt.shiftKey) {
          saveDescription(currentDescription);
          // Prevent newlines
          evt.preventDefault();
          setIsEditingDescription(false);
          descriptionRef.current?.blur();
        }
      },
      [currentDescription, saveDescription],
    );

    if (!canEdit && !currentDescription) {
      return null;
    }

    return (
      <DescriptionWrapper>
        {canEditWithMagic &&
          (magicDescLoading ? (
            <MagicDescLoadingSpinner />
          ) : (
            <HexTooltip
              content="Generate description with Magic"
              position="top"
            >
              <MagicOnboardingPopover>
                <MagicDescribeButton
                  extraSmall={true}
                  icon={<MagicIcon />}
                  minimal={true}
                  onClick={onboardedToMagic ? magicDescribe : undefined}
                />
              </MagicOnboardingPopover>
            </HexTooltip>
          ))}

        <DescriptionTextArea
          ref={descriptionRef}
          $editable={canEdit}
          $small={small}
          id={PROJECT_DESCRIPTION_HTML_ID}
          maxLength={DESCRIPTION_MAX_LENGTH}
          placeholder={
            magicDescLoading
              ? "Generating description with Magic..."
              : "Add description..."
          }
          readOnly={!canEdit || magicDescLoading}
          rows={1}
          onChange={descriptionTextAreaCallback}
          onFocus={maybeFocusDescription}
          onKeyDown={handleKeydown}
          onKeyPress={handleKeyPress}
          value={currentDescription}
          // eslint-disable-next-line react/jsx-no-bind
          onBlur={() => saveDescription(currentDescription)}
        />
        {editingDescription && (
          <DescriptionLengthLimit
            count={currentDescription.length}
            max={DESCRIPTION_MAX_LENGTH}
          />
        )}
      </DescriptionWrapper>
    );
  });
